export const PAPER_ELEVATION_APP_FOR_EDIT_CELL = 1;

export const EXTRA_ROWS_NUM = 30;

export const IME_KEY_CODE = 229;

// gcp config
export const GOOGLE_AUTH_CLIENT_ID = process.env.GOOGLE_AUTH_CLIENT_ID || "google_auth_client_id";

// slack config
export const SLACK_CLIENT_ID = process.env.SLACK_CLIENT_ID || "";
export const SLACK_CLIENT_SECRET = process.env.SLACK_CLIENT_SECRET || "";
export const SLACK_REDIRECT_URI = process.env.SLACK_REDIRECT_URI || "";
export const SLACK_REDIRECT_URI_FOR_INDIVIDUAL = process.env.SLACK_REDIRECT_URI_FOR_INDIVIDUAL || "";

// chatwork config
export const CHATWORK_CLIENT_ID = process.env.CHATWORK_CLIENT_ID || "";
export const CHATWORK_CLIENT_SECRET = process.env.CHATWORK_CLIENT_SECRET || "";
export const CHATWORK_REDIRECT_URI = process.env.CHATWORK_REDIRECT_URI || "";

export const CHATWORK_OAUTH_URL = "https://www.chatwork.com/packages/oauth2/login.php";
export const CHATWORK_API_AUTH_TOKEN_URL = "https://oauth.chatwork.com/token";
export const CHATWORK_USER_SCOPE = "rooms.all:read_write%20users.all:read";

export const SLACK_API_OAUTH_V2_ACCESS_URL = "https://slack.com/api/oauth.v2.access";
export const SLACK_API_AUTH_TEST_URL = "https://slack.com/api/auth.test";
export const SLACK_API_AUTH_REVOKE_URL = "https://slack.com/api/auth.revoke";
export const SLACK_API_OAUTH_AUTH_URL = "https://slack.com/oauth/v2/authorize";
export const SLACK_USER_SCOPE =
  "channels:read,channels:manage,channels:join,chat:write,groups:read,mpim:read,im:read,users:read";
export const SLACK_USER_SCOPE_FOR_INDIVIDUAL = "chat:write,im:write";
export const GOOGLE_CLIENT_ID_FOR_CALENDAR =
  process.env.GOOGLE_CLIENT_ID_FOR_CALENDAR || "google_client_id_for_calendar";
export const GOOGLE_CLIENT_SECRET_FOR_CALENDAR =
  process.env.GOOGLE_CLIENT_SECRET_FOR_CALENDAR || "google_client_secret_for_calendar";

// google tag manager
export const GOOGLE_TAG_MANAGER_ID = process.env.GOOGLE_TAG_MANAGER_ID || "";
